@import '../../variables.scss';
.publicTermsAndConditionsPage, .privateTermsAndConditionsPage {
    .richtextContent {
        font-family: $fontFamily;
    }
    /**
    * Basic CSS Media Query Template
    * ------------------------------------------
    *  Responsive Grid Media Queries - 1280, 1024, 768, 480
    *   1280-1024   - desktop (default grid)
    *   1024-768    - tablet landscape
    *   768-480     - tablet 
    *   480-less    - phone landscape & smaller
    * --------------------------------------------
    */
    @media all and (min-width: 1024px) and (max-width: 1280px) {
        padding: 24px;
        .richtextContent {

        }
    }

    @media all and (min-width: 768px) and (max-width: 1024px) {
        padding: 20px;
        .richtextContent {

        }
    }

    @media all and (min-width: 480px) and (max-width: 768px) {
        padding: 16px;
        .richtextContent {

        }
    }

    @media all and (max-width: 480px) {
        padding: 14px;
        .richtextContent {

        }
    }
}