.checkoutDrawer {
    .checkoutAnimalCard {
        .ant-card-body {
            padding: 10px;
        }
        .checkoutAnimalInfoContainer{
            display: flex;
            align-items: center;
            gap: 15px;
            img {
                width: 100px;
                height: 100px;
            }
            .orderTitleBreederContent {
                display: flex;
                flex-direction: column;
                .orderTitleTxt {
					font-weight: 600;
					font-size: 15px;
				}
				.orderTitleOwnerTxt {
					font-size: 12px;
				}
            }
        }
    }
    .checkoutFormContainer {
        margin: 35px 0;
        .checkoutFormStepper {
            margin-bottom: 25px;
        }
        .checkoutOrderForm {
            .ant-input {
                margin-bottom: 15px;
            }
            .checkoutOrderFormItemCol {
                width: 100%;
                .ant-space-item {
                    width: 50%;
                }
            }
        }
    }
    .checkoutCta {
        display: flex;
        justify-content: center;
        gap: 15px;
        button {
            width: 100%;
        }
    }
}

/* Mobile screen adjustments */
@media only screen and (max-width: 768px) {
    :where(.ant-drawer .ant-drawer-content) {
      width: 100vw !important; /* Full viewport width */
      height: 100vh !important; /* Full viewport height */
      overflow: auto !important;
      background: #ffffff !important; /* Keep the background white */
      pointer-events: auto !important;
    }
  
    /* Here you can include other style adjustments for child elements within the drawer */
    .ant-drawer .ant-drawer-content .checkoutDrawer .checkoutAnimalCard .ant-card-body {
      padding: 10px;
      /* ... other styles ... */
    }
  
    /* Continue with other nested selectors as needed */
    /* ... */
  }
/* Mobile screen adjustments */
@media only screen and (max-width: 768px) {
    .ant-drawer .ant-drawer-content {
        /* On mobile, you may want to adjust the padding or max-width */
        width: 100%; /* Full width on mobile */
        padding: 10px; /* Adjust padding for mobile */
    }

    .ant-drawer .ant-drawer-content .content-container {
        max-width: none; /* On mobile, content can take the full width */
    }
}
/* Ensure that this media query is below any other existing styles in your CSS */
@media only screen and (max-width: 625px) {
    .ant-drawer-content-wrapper {
      width: 95% !important; /* Force full width on screens narrower than 625px */
    }
    
    /* Ensure the content inside the drawer is centered */
    .ant-drawer .ant-drawer-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start; /* Align content to the top */
    }
  
    /* Target the checkout drawer specifically */
    .ant-drawer-content.checkoutDrawer {
      width: auto; /* Reset any specific width to default to allow it to be governed by the wrapper */
      max-width: 100%; /* Prevent it from extending beyond the viewport width */
    }
  }
  