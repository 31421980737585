.editProductPopup {
    .editProductForm {
        margin-top: 20px;
        .editProductCta {
            margin-bottom: 0;
            .editProductCtaSpace {
                display: flex;
                justify-content: flex-end;
            }
        }
        .productPricing {
            width: 100%;
        }
    }
}
.editProductPopup {
    .ant-modal-body {
        max-height: 80vh;
        overflow-y: auto;
        padding: 24px;
    }

    .editProductForm {
        .ant-form-item {
            margin-bottom: 16px;
        }

        .ant-input-number {
            width: 100%;
        }

        .ant-upload-list {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }

        .ant-upload.ant-upload-select-picture-card {
            width: 100px;
            height: 100px;
            margin: 0;
        }

        .ant-upload-list-picture-card-container {
            width: 100px;
            height: 100px;
        }
    }
}

.upload-video .ant-upload-list-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .upload-video .ant-upload-list-item-info {
    max-width: 100%;
  }
  
  .upload-video .ant-upload-list {
    padding: 10px;
  }
  
  .upload-video .ant-upload {
    display: inline-block;
  }
  