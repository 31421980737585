@import '../../variables.scss';
.registerPage {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	.registerPageRow {
		width: 100%;
		background-color: #fafafa;
	}
	.registerHeroCol {
		height: 750px;
	}
	.registerHero {
		height: 750px;
		width: 100%;
		object-fit: cover;
		object-position: top;
	}
	.breederPhotoInputContainer {
		.ant-form-item-control-input {
			margin-bottom: 35px;
		}
		.ant-form-item-control-input-content {
			position: relative;
		}
		.ant-space {
			width: 100%;
			.ant-space-item {
				width: 100%;

				&:nth-child(2) {
					position: absolute;
					left: 0;
					right: 0;
					margin: auto;
					text-align: center;
					bottom: -35px;
				}
			}
		}
		.ant-upload-select {
			width: 100%;
		}
		button {
			background-color: #eaeaea;
			width: 100%;
			height: 125px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #808080;
			z-index: 1;
			&.hasImg {
				padding: 0;
			}
			.breederCoverPhotoImg {
				width: 100%;
				object-fit: cover;
				height: 100%;
				border-radius: 8px;
				object-position: center;
			}
		}
		.ant-avatar {
			z-index: 1;
			background: #eaeaea;
			i {
				color: #808080;
			}
		}
	}
	.registerCard {
		width: 50%;
		margin: auto;
		&.fixedHeight {
			height: 550px;
		}
		.ant-card-body {
			padding: 34px;
			height: 100%;
			position: relative;
		}
		.registerHeaderTitle {
			text-align: center;
		}
		.registerStepper {
			width: 60%;
			margin: auto;
		}
		.registrationFormHeader {
			text-align: center;
			display: block;
			margin: 25px;
		}
	}
	.registerForm {
		margin-top: 15px;
		.registerNameContainer {
			.ant-form-item {
				margin-bottom: 0;
				width: 50%;
			}
			.ant-form-item-control-input-content {
				display: flex;
				gap: 25px;
			}
		}
		.registerCta {
			width: 100%;
		}
		.prefixOptionalExtra {
			color: orange;
		}
		.uploadBreederCard {
			.ant-upload {
				width: 100%;
			}
			.addBreederCard {
				width: 100%;
				height: 50px;
				border-color: red;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				color: $textGray;
			}
		}
		.custom-breeder-card-upload {
			border: 1px solid #ccc;
			display: flex;
			cursor: pointer;
			width: 100%;
			text-align: center;
			justify-content: center;
			gap: 7px;
			padding: 15px;
			align-items: center;
			border-radius: 8px;
			input[type="file"] {
				display: none;
			}
			&.disabled {
				cursor: not-allowed;
				background-color: #f0f0f0;
				border: 1px solid #ddd;
				color: #888;
			
				i {
				  color: #888;
				}
			}
		}
	}
	.registerCtaText {
		text-align: center;
	}
	.accountTypeSelectionParentContainer {
		width: 100%;
		.accountTypesSelectionContainer {
			display: flex;
			gap: 25px;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;
		}
		button {
			width: 100%;
			color: $primaryColorLight;
			border-style: solid;
			border-color: $primaryColorLight;
			font-weight: 600;
		}
	}
	.registerSubTxt {
		color: $textGray;
		text-align: center;
		margin: 10px;
		font-size: 12px;
	}
	/**
	* Basic CSS Media Query Template
	* ------------------------------------------
	*  Responsive Grid Media Queries - 1280, 1024, 768, 480
	*   1280-1024   - desktop (default grid)
	*   1024-768    - tablet landscape
	*   768-480     - tablet 
	*   480-less    - phone landscape & smaller
	* --------------------------------------------
	*/
	@media all and (min-width: 1024px) and (max-width: 1280px) {
		.registerCard {
			width: 85%;
		}
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.registerCard {
			width: 85%;
			.registerStepper {
				width: 90%;
			}
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.registerCard {
			width: 85%;
			.registerStepper {
				width: 90%;
			}
		}
	}

	@media all and (max-width: 480px) {
		.registerCard {
			width: 90%;
			padding-top: 40px;
			.registerStepper {
				width: 90%;
			}
		}
		.registerPageRow {
			margin: 0!important;
		}
	}
}
