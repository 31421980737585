@import '../../variables.scss';

.ordersPage {
	.ordersFilterContainer {
		margin-top: 25px;
	}
	.ordersTableContainer {
		margin-top: 25px;
		.ant-table {
			background: transparent;
			thead {
				th,
				td {
					background: transparent;
				}
			}
			tbody {
				tr {
					&:nth-child(odd) {
						background-color: #f8fff8;
					}
				}
			}
		}

		.orderTitleContent {
			display: flex;
			align-items: center;
			gap: 15px;
			.ant-image-img {
				width: 100px;
				border-radius: 4px;
			}
			.orderTitleBreederContent {
				display: flex;
				flex-direction: column;
				.orderTitleTxt {
					font-weight: 600;
					font-size: 15px;
				}
				.orderTitleOwnerTxt {
					font-size: 12px;
				}
			}
		}
		.orderPricing {
			color: $primaryColorLight;
			font-weight: 700;
		}
		.orderStatusTag {
			text-transform: capitalize;
		}
	}
	/**
	* Basic CSS Media Query Template
	* ------------------------------------------
	*  Responsive Grid Media Queries - 1280, 1024, 768, 480
	*   1280-1024   - desktop (default grid)
	*   1024-768    - tablet landscape
	*   768-480     - tablet 
	*   480-less    - phone landscape & smaller
	* --------------------------------------------
	*/
	@media all and (min-width: 1024px) and (max-width: 1280px) {
		.ordersFilterContainer {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		.ordersTableContainer {
			padding-left: 12px !important;
			padding-right: 12px !important;
			.ant-table {
				overflow: auto;
			}
		}
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.ordersFilterContainer {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		.ordersTableContainer {
			padding-left: 12px !important;
			padding-right: 12px !important;
			.ant-table {
				overflow: auto;
			}
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.ordersFilterContainer {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		.ordersTableContainer {
			padding-left: 12px !important;
			padding-right: 12px !important;
			.ant-table {
				overflow: auto;
			}
		}
	}

	@media all and (max-width: 480px) {
		.ordersFilterContainer {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		.ordersTableContainer {
			padding-left: 12px !important;
			padding-right: 12px !important;
			.ant-table {
				overflow: auto;
			}
		}
	}
}

.ordersTableColCtaDropdown {
	.orderTableColCta {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 5px;
	}
	.receivedCta {
		.orderTableColCta {
			color: #98b76b;
		}
		&:hover {
			background-color: #98b76b !important;
			.orderTableColCta {
				color: #ffffff;
			}
		}
	}
	.deleteCta {
		.orderTableColCta {
			color: #ff4d4f;
		}
		&:hover {
			background-color: #ff4d4f !important;
			.orderTableColCta {
				color: #ffffff;
			}
		}
	}
}
