.pageTitle {
}

/**
 * Basic CSS Media Query Template
 * ------------------------------------------
 *  Responsive Grid Media Queries - 1280, 1024, 768, 480
 *   1280-1024   - desktop (default grid)
 *   1024-768    - tablet landscape
 *   768-480     - tablet 
 *   480-less    - phone landscape & smaller
 * --------------------------------------------
 */
@media all and (min-width: 1024px) and (max-width: 1280px) {
        .pageTitle {
		padding-left: 12px !important;
		padding-right: 12px !important;
		margin-bottom: 0 !important;
		margin-top: 0 !important;
	}
}

@media all and (min-width: 768px) and (max-width: 1024px) {
        .pageTitle {
		padding-left: 12px !important;
		padding-right: 12px !important;
		margin-bottom: 0 !important;
		margin-top: 0 !important;
	}
}

@media all and (min-width: 480px) and (max-width: 768px) {
        .pageTitle {
		padding-left: 12px !important;
		padding-right: 12px !important;
		margin-bottom: 0 !important;
		margin-top: 0 !important;
	}
}

@media all and (max-width: 480px) {
	.pageTitle {
		padding-left: 12px !important;
		padding-right: 12px !important;
		margin-bottom: 0 !important;
		margin-top: 0 !important;
	}
}
