.contactPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1600px;
    width: 100%;
    box-sizing: border-box;
    margin: auto;
    padding: 50px 0;

    .contactContent {
        display: flex; // Updated to flex
        align-items: start; // Align items vertically
        justify-content: space-between; // Space between text and image
        padding: 25px;
        margin-bottom: 25px;
        margin-left: 50px;
        margin-right: 50px;
        background: #f0f2f5;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);

        .textSection {
            flex: 1; // Takes as much space as possible
            margin-right: 20px; // Space between text and image
        }

        .imageSection {
            flex-basis: 40%; // Image takes up 40% of space
            img {
                width: 100%; // Make image full width of the section
                height: auto; // Keep aspect ratio
                border-radius: 8px; // Optional: Rounded corners for the image
                }
        }
    }

    @media all and (max-width: 768px) {
        .contactContent {
            flex-direction: column;
            .textSection {
                margin-right: 0;
            }
            .imageSection {
                margin-top: 20px;
            }
        }
    }
}
