/* General Page Fix */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scroll */
    width: 100%;
}

/* Container Fix */
.content-container {
    padding: 1.5rem; /* Increase padding for better spacing */
    max-width: 100%;
    text-align: center; /* Center all content including button */
}

/* Heading Text */
.heading-text {
    font-size: 50px; /* Adjusted for large screens */
    color: #3C3A3B;
    text-align: center;
    font-family: Impact, sans-serif;
    font-weight: 400;
    line-height: 1.2;
    margin-bottom: 20px; /* Add margin for better spacing */
}

.section-title {
    color: #A1C575;
    font-size: 28px;
    font-family: Impact, sans-serif;
    font-weight: 400;
    margin-bottom: 20px; /* Adjust spacing between title and text */
    text-align: center;
}

.section-text {
    color: black;
    font-size: 18px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 30px; /* Increase bottom margin for better spacing */
    padding: 0 20px;
}

/* Button Styling */
.sign-up-button {
    width: 100%;
    height: 50px;
    background-color: #A1C575;
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto; /* Center the button and add margin above/below */
    color: white;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    border-radius: 5px; /* Add rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    cursor: pointer;
    padding: 0 20px; /* Ensure padding inside the button */
}

/* Image Scaling for Responsive Design */
.landing-image {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 20px auto; /* Add margin for spacing between elements */
}

.app-image {
    width: 100%;
    max-width: 400px; /* Limit image size */
    margin: 20px auto; /* Add margin for spacing */
    display: block;
    padding: 0 30px; /* Add 30px padding around the image for mobile */
}

/* Media Queries for Mobile */
@media (max-width: 768px) {
    .heading-text {
        font-size: 35px;
        line-height: 1.1;
    }

    .section-title {
        font-size: 24px;
    }

    .section-text {
        font-size: 16px;
        padding: 0 15px;
    }

    .sign-up-button {
        height: 45px;
        font-size: 14px;
        margin: 20px auto; /* Keep button centered and spaced */
    }

    .landing-image, .app-image {
        max-width: 100%; /* Ensure the image takes full width */
        height: auto;
        padding: 0 15px; /* Adjust padding on mobile */
    }
}

@media (max-width: 480px) {
    .heading-text {
        font-size: 35px;
        padding: 25px 35px 0px 35px;
    }

    .section-title {
        font-size: 20px;
    }

    .section-text {
        font-size: 14px;
        padding: 0 10px;
    }

    .sign-up-button {
        height: 40px;
        font-size: 12px;
        max-width: 250px;
        margin: 15px auto; /* Keep button centered */
    }

    .app-image {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        padding: 0 30px; /* Add 30px padding on mobile */
    }
}
/* Default styling for the Hero section */
.hero-row {
    height: 30vh; /* Default for larger screens */
    display: flex;
    align-items: center;
}

/* Default styling for the Main section */
.main-row {
    padding: 60px 0; /* Default padding */
    display: flex;
    align-items: center;
}

/* Media Queries for Mobile (max-width: 768px) */
@media (max-width: 768px) {
    .hero-row {
        height: 20vh; /* Set to 20vh for mobile */
    }

    .main-row {
        padding: 25px 0; /* Reduce top and bottom padding to 25px */
    }
}
/* Default layout for larger screens - Image first, text second */
.secure-payment-row {
    flex-direction: row-reverse; /* Image first, text second */
}

/* On mobile (max-width: 768px), reverse the order */
@media (max-width: 768px) {
    .secure-payment-row {
        flex-direction: column-reverse; /* Text first, image second */
    }

    /* Adjust any additional padding or margins if needed */
    .secure-payment-row .landing-image {
        margin-bottom: 20px; /* Add space between image and text */
    }
}
