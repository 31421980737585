.privateFooter {
	.privateFooterRow {
		max-width: 1600px;
		width: 100%;
		box-sizing: border-box;
		margin: auto;
		padding: 25px 0;
		.footerLogo {
			width: 180px;
		}
		.footerLinks {
			b {
				color: #ffffff;
			}
			ul {
				padding: 0;
				list-style-type: none;
				margin-bottom: 0;
				li {
					line-height: 25px;
					a {
						text-decoration: none;
						color: #ffffff;
					}
				}
			}
		}
	}
	.footerCopyrightTxt {
		display: flex;
		justify-content: center;
		margin: 0;
		text-align: center;
		align-items: center;
	}
	/**
	* Basic CSS Media Query Template
	* ------------------------------------------
	*  Responsive Grid Media Queries - 1280, 1024, 768, 480
	*   1280-1024   - desktop (default grid)
	*   1024-768    - tablet landscape
	*   768-480     - tablet 
	*   480-less    - phone landscape & smaller
	* --------------------------------------------
	*/
	@media all and (min-width: 1024px) and (max-width: 1280px) {
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.privateFooterRow {
			.footerLogo {
				margin: auto;
				text-align: center;
				display: flex;
				justify-content: center;
				margin-bottom: 50px;
				width: 225px;
			}
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.privateFooterRow {
			.footerLogo {
				margin: auto;
				text-align: center;
				display: flex;
				justify-content: center;
				margin-bottom: 50px;
				width: 225px;
			}
		}
	}

	@media all and (max-width: 480px) {
		.privateFooterRow {
			.footerLogo {
				margin: auto;
				text-align: center;
				display: flex;
				justify-content: center;
				margin-bottom: 50px;
				width: 200px;
			}
			.footerLinks {
				&:not(:last-child) {
					margin-bottom: 50px;
				}
			}
		}
	}
}
