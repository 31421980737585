$primaryColor: #98b76b;
$primaryColorLight: #A1C575;
$primaryColorLighter: rgba(161, 197, 117, 0.50);
$darkGray: #878587;
$darkerGray: #BBBABB;
$darkeGrayLight: rgba(187, 186, 187, 0.5);
$blackTextColor: #3C3A3B;
$secondaryColor: #52abfa;
$bgPrimaryColor: #fafafa;
$textGray: rgba(0, 0, 0, 0.45);
$fontFamily: 'Roboto', sans-serif;
