@import '~react-image-gallery/styles/scss/image-gallery.scss';

.animalPage {
	.animalContentRow {
		width: 100%;
		.animalPreviewContainer {
			.thumbnailAnimalGallery {
				height: 100px;
				width: 100px;
				cursor: pointer;
				.image-gallery-thumbnail-inner {
					width: 100%;
					height: 100%;
				}
				.image-gallery-thumbnail-image {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
		.animalInfoContainer {
			.animalCategory {
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 900;
				color: lightgray;
			}
			.animalNameHeader {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 25px;
				.animalTitle {
					display: flex;
					align-items: center;
					gap: 15px;
					.animalName {
						margin: 0;
					}
					i {
						cursor: pointer;
					}
					.saved {
						color: red;
					}
				}
				.animalPricing {
					margin: 0;
					color: #97c66a;
					font-weight: 700;
				}
			}
			.animalFavoritesInfo {
				display: flex;
				align-items: center;
				gap: 15px;
				margin-bottom: 15px;
			}
			.animalInfoTabs {
				margin-bottom: 25px;
				.animalLocationMap {
					iframe {
						width: 100%;
					}
				}
			}
			.animalShareSocials {
				display: flex;
				align-items: center;
				gap: 15px;
				margin: 25px 0;
				.ant-typography {
					text-transform: uppercase;
					font-weight: 600;
				}
				.animalSocials {
					display: flex;
					align-items: center;
					gap: 7px;
					i {
						cursor: pointer;
					}
				}
			}
			.animalCta {
				display: flex;
				align-items: center;
				gap: 25px;
				button {
					width: 100%;
					text-transform: uppercase;
					font-weight: bold;
				}
			}
		}
	}
	/**
	* Basic CSS Media Query Template
	* ------------------------------------------
	*  Responsive Grid Media Queries - 1280, 1024, 768, 480
	*   1280-1024   - desktop (default grid)
	*   1024-768    - tablet landscape
	*   768-480     - tablet 
	*   480-less    - phone landscape & smaller
	* --------------------------------------------
	*/
	@media all and (min-width: 1024px) and (max-width: 1280px) {
		.animalContentRow {
			margin-left: 0!important;
			margin-right: 0!important;
		}
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.animalContentRow {
			margin-left: 0!important;
			margin-right: 0!important;
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.animalContentRow {
			margin-left: 0!important;
			margin-right: 0!important;
		}
	}

	@media all and (max-width: 480px) {
		.animalContentRow {
			margin-left: 0!important;
			margin-right: 0!important;
		}
	}
}
	.animalNameHeader {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
  
	  .animalTitle {
		.animalName {
		  font-size: 38px; // Default for desktop
  
		  @media (max-width: 768px) {
			font-size: 28px; // Adjust for tablet
		  }
  
		  @media (max-width: 480px) {
			font-size: 22px; // Adjust for mobile phones
		  }
		}
	  }
  
	  .animalPricing {
		font-size: 28px; // Default size for desktop
  
		@media (max-width: 768px) {
		  font-size: 22px; // Adjust for tablet
		}
  
		@media (max-width: 480px) {
		  font-size: 18px; // Adjust for mobile
		}
	  }
	}
  