@import './variables.scss';
body,
#root,
html {
	height: 100%;
	margin: 0;
}

.text-center {
	text-align: center;
}

.absolute-centered {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	align-items: center;
	display: flex;
	justify-content: center;
	margin: auto;
	z-index: 0;
}

.clickable {
	cursor: pointer;
}

/* General scrollbar styles */
::-webkit-scrollbar {
	width: 10px;
	background-color: rgba(187, 186, 187, 0.3);
	border-radius: 30px;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
	background-color: #a1c575;
	border-radius: 30px;
}

/* Adjust border-radius when scroll is at the top */
::-webkit-scrollbar:vertical:window-inactive {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
}

/* Adjust border-radius when scroll is at the bottom */
::-webkit-scrollbar:vertical:window-inactive:corner-present {
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

/* Remove bottom border radius when scroll is at the bottom */
::-webkit-scrollbar:vertical:window-inactive:no-button:corner-present {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.primary-color-icon {
	color: $primaryColorLight!important;
}
