@import '../../variables.scss';

.animalBreederInfo {
	.animalBreederDetails {
		display: flex;
		align-items: center;
		gap: 10px;
		.animalBreederInfoMeta {
			.animalBreederName {
				display: flex;
				align-items: center;
				gap: 7px;
			}
			.businessName {
				margin: 0;
			}
			.businessDescription {
				margin: 0;
			}
			.verified {
				color: #52c41a;
			}
			.unverified {
				color: $textGray;
			}
		}
	}
	.animalBreederLocation {
		display: flex;
		align-items: center;
		gap: 7px;
		margin: 15px 0 0;
		.location {
			color: $textGray;
			margin: 0;
		}
	}
}
