.profilePage {
	.profileViewRow {
		margin: 25px 0;
		width: 100%;

		.notificationCountBadge {
			margin-left: 5px;
		}
	}

	.subscription-info {
		margin-top: 20px;
		padding: 20px;
		border: 1px solid #f0f0f0;
		border-radius: 8px;
		background-color: #fafafa;

		h3 {
			font-size: 18px;
			margin-bottom: 10px;
		}

		p {
			font-size: 14px;
			margin-bottom: 15px;
			color: #555;
		}

		button {
			margin-top: 10px;
		}
	}

	/**
	 * Responsive Grid Media Queries - 1280, 1024, 768, 480
	 * --------------------------------------------
	 */
	@media all and (min-width: 1024px) and (max-width: 1280px) {
		.profileViewRow {
			margin-left: 0 !important;
			margin-right: 0 !important;

			.ant-menu {
				width: 100% !important;
			}
		}
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.profileViewRow {
			margin-left: 0 !important;
			margin-right: 0 !important;

			.ant-menu {
				width: 100% !important;
			}
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.profileViewRow {
			margin-left: 0 !important;
			margin-right: 0 !important;

			.ant-menu {
				width: 100% !important;
			}
		}
	}

	@media all and (max-width: 480px) {
		.profileViewRow {
			margin-left: 0 !important;
			margin-right: 0 !important;

			.ant-menu {
				width: 100% !important;
			}
		}
	}
}
