.contactBreederPopup {
	.contactBreederInfo {
		margin: 25px 0;
		.animalBreederLocation {
			display: none;
		}
		.animalBreederInfoMeta {
			width: 90%;
		}
	}
	.contactBreederInput {
		margin: 0 0 25px;
		.contactBreederInputTxt {
		}
	}
	.errorMsg {
		color: #ff4d4f;
	}
}
