.forgotPasswordPage {
	margin: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	background-repeat: no-repeat;
	background-image: url('../../assets/images/main_placeholder_blurred.jpg');
	background-size: cover;
	height: 90vh;
	.forgotPasswordCard {
		margin: 25px;
		.ant-card-body {
			padding: 35px;
		}
		.forgotPasswordIcon {
			text-align: center;
			justify-content: center;
			display: flex;
			align-items: center;
			margin: auto;
			width: 90px;
		}
		.forgotPasswordHeader {
			text-align: center;
		}
		.forgotPasswordSub {
			margin-top: 35px;
			color: #000000;
			margin-bottom: 0;
		}
		.forgotPasswordForm {
			margin-top: 15px;
			.newPasswordInput {
				margin-bottom: 15px;
			}
			.forgotPasswordCta {
				width: 100%;
			}
			.resendResetCode {
				font-size: 12px;
				margin-bottom: 15px;
				display: block;
			}
		}
	}
}
