@import '../../variables';

.privateLayoutContainer {
	background: $bgPrimaryColor;
	height: auto;
	min-height: 100%;
	.mainFooter {
		background-color: #3c3a3b;
		margin-top: 65px;
	}
	/**
    * Basic CSS Media Query Template
    * ------------------------------------------
    *  Responsive Grid Media Queries - 1280, 1024, 768, 480
    *   1280-1024   - desktop (default grid)
    *   1024-768    - tablet landscape
    *   768-480     - tablet 
    *   480-less    - phone landscape & smaller
    * --------------------------------------------
    */
	@media all and (min-width: 1024px) and (max-width: 1280px) {

	}

	@media all and (min-width: 768px) and (max-width: 1024px) {

	}

	@media all and (min-width: 480px) and (max-width: 768px) {

	}

	@media all and (max-width: 480px) {
		.ant-layout-header {
			padding-inline: 0;
		}
	}
}

.customLayoutWidth {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: 1600px;
	width: 100%;
	box-sizing: border-box;
	margin: auto;
	padding: 50px 0;
}

@media screen and (max-width: 768px) {

	.customLayoutWidth {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		max-width: 1600px;
		width: 100%;
		box-sizing: border-box;
		margin: auto;
		padding: 20px 0 !important;
	}
}