.breederStorefront {
	.breederStoreContent {
		.ant-row {
			width: 100%;
		}
		.breederStoreFilterCategory {
			margin-bottom: 35px;
			&.relativePos {
				margin-top: -100px;
			}
			&.unauthMarketplace {
				display: none;
			}
		}
		.breederStorefrontFilters {
			margin-bottom: 35px;
			.searchFilter {
				input {
					&::placeholder {
						color: #000000;
					}
				}
			}
			.sortBySelect {
				width: 100%;
				.ant-select-selection-placeholder {
					color: #000000;
				}
			}
			.sortFilterCta {
				button {
					width: 100%;
					padding: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 7px;
					.sortFilterCtaTxt {
						font-size: 17px;
						color: #000000;
					}
				}
			}
		}
	}
	/**
    * Basic CSS Media Query Template
    * ------------------------------------------
    *  Responsive Grid Media Queries - 1280, 1024, 768, 480
    *   1280-1024   - desktop (default grid)
    *   1024-768    - tablet landscape
    *   768-480     - tablet 
    *   480-less    - phone landscape & smaller
    * --------------------------------------------
    */
	@media all and (min-width: 1024px) and (max-width: 1280px) {
		.breederStoreContent {
			.ant-row {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.breederStoreContent {
			.ant-row {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.breederStoreContent {
			.ant-row {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
	}

	@media all and (max-width: 480px) {
		.breederStoreContent {
			.ant-row {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
		.breederStorefrontFilters {
			row-gap: 16px!important;
			.sortFilterCta {
				button {
					.sortFilterCtaTxt {
						display: none;
					}
				}
			}
		}
	}
}

.breederStoreFilterCategory.custom-width {
    width: 80%; // or any specific width you want
    margin: 0 auto; // centre the container

    // Ensuring it overrides any padding set by ant-row
    &.ant-row-center {
        padding: 0 !important; // Override padding if needed
    }
}
.breederStorefront {
	.breederStorefrontFilters {
	  padding: 24px 0;
	  
	  .searchFilter {
		width: 100%;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #E8E8E8;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
		
		.ant-select-selector {
		  height: 48px !important;
		  padding: 0 16px !important;
		  border: none !important;
		  
		  .ant-select-selection-search-input {
			height: 48px !important;
			font-size: 16px;
		  }
		  
		  .ant-select-selection-placeholder,
		  .ant-select-selection-item {
			line-height: 48px !important;
			color: #333333;
		  }
		}
		
		.ant-select-arrow {
		  color: #8CC63F;
		  .ri-search-line {
			font-size: 20px;
		  }
		}
	  }
  
	  .sortBySelect {
		width: 100%;
		height: 48px;
		border-radius: 8px;
		background: #FFFFFF;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
		
		.ant-select-selector {
		  height: 48px !important;
		  border-radius: 8px !important;
		  display: flex;
		  align-items: center;
		}
		
		.ant-select-selection-item {
		  font-size: 16px;
		  color: #333333;
		}
	  }
	}
  }

  .searchFilter {
	.ri-close-line {
	  color: #999;
	  &:hover {
		color: #666;
	  }
	}
	.ant-select-clear {
		right: 35px;
	  }
  }

  .breederStorefrontFilters {
	@media (max-width: 768px) {
	  padding: 16px 24px;
	  
	  .ant-row {
		gap: 12px !important;
	  }
	  
	  .searchFilter {
		width: 100%;
	  }
	  
	  .sortBySelect {
		width: 100%;
	  }
	  
	  .ant-col {
		width: 100%;
	  }
	}
  }

  .sort-dropdown {
	min-width: 150px !important;
  }
  
  .sortBySelect {
	.ant-select-selection-placeholder i {
	  font-size: 20px;
	}
  }