@import '../../variables';

.inboxPage {
	.inboxContent {
		margin-top: 25px;
		height: 70vh;
		position: relative;
		overflow-y: hidden;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
		border-radius: 20px;
		.inboxConversation {
			position: relative;
			height: 100%;
			.inboxConversationHeader {
				padding: 25px;
				background-color: #ffffff;
				height: 110px;
				box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
				.inboxConversationHeaderTxt {
					margin: 0;
				}
				.conversationStatusContainer {
					display: flex;
					align-items: center;
					gap: 5px;
					.conversationStatusColor {
					}
					.conversationStatusTxt {
					}
				}
			}
			.inboxConversationContent {
				padding: 25px;
				&.emptyConversation {
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				&.conversationContent {
					padding: 35px 25px;
					display: flex;
					flex-direction: column;
					gap: 40px;
					max-height: 553px;
					overflow: auto;
					background: #fafafa;
					padding-bottom: 225px;
				}
				.selectedViewingDate {
					position: sticky;
					top: 0;
					left: 0;
					right: 0;
					margin: auto;
					text-align: center;
				}
			}
			.inboxConversationCta {
				position: absolute;
				width: 100%;
				bottom: 180px;
				background: #ffffff;
				box-shadow: 0px -4px 16px 0px rgba(0, 0, 0, 0.08);
				.inboxConversationInput {
					width: 85%;
					padding: 25px 25px 0 25px;
				}
				.inboxConversationSend {
					position: absolute;
					right: 20px;
					bottom: 25px;
				}
			}
		}
	}
	.messageBackBtn {
		padding-left: 12px;
		padding-right: 12px;
	}
	/**
	* Basic CSS Media Query Template
	* ------------------------------------------
	*  Responsive Grid Media Queries - 1280, 1024, 768, 480
	*   1280-1024   - desktop (default grid)
	*   1024-768    - tablet landscape
	*   768-480     - tablet 
	*   480-less    - phone landscape & smaller
	* --------------------------------------------
	*/
	@media all and (min-width: 1024px) and (max-width: 1280px) {
		.inboxContent {
			border-radius: 0;
		}
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.inboxContent {
			border-radius: 0;
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.inboxContent {
			border-radius: 0;
		}
	}

	@media all and (max-width: 480px) {
		.inboxContent {
			border-radius: 0;
		}
	}
}

.inboxList {
	overflow-y: auto;
	height: 100%;
	border-right: 1px solid #d9d9d9;
	background-color: $darkeGrayLight;
	.inboxSearchContainer {
		padding: 35px 25px;
		.inboxSearch {
			border: none;
			box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
			border-radius: 50px;
			margin-bottom: 2px;
		}
	}
	.inboxListItem {
		background: #ffffff;
		padding: 15px;
		cursor: pointer;
		height: 110px;
		.inboxItemTitleContainer {
			display: flex;
			justify-content: space-between;
			.inboxItemTitleName {
			}
			.inboxItemTitleDate {
				font-weight: 400;
				font-size: small;
			}
		}
		.ant-list-item-meta-description {
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		&:hover {
			background-color: $primaryColor;
			.ant-list-item-meta-title {
				color: #ffffff;
			}
			.ant-list-item-meta-description {
				color: #ffffff;
			}
		}
		&.selected {
			background-color: $primaryColor;
			.ant-list-item-meta-title {
				color: #ffffff;
			}
			.ant-list-item-meta-description {
				color: #ffffff;
			}
		}
	}
}

@media screen and (max-width: 768px) {
    // Assuming there's a parent container that holds both the inbox list and conversation
    .react-reveal { // Replace with the actual parent container class if it exists
        display: block;
        flex-direction: column;
    }

    // Style for the inbox list to make it visible and properly sized
    .inboxList {
        display: block; // Ensure it's visible
        width: 100%;
        order: 1; // Inbox list comes first
        flex-basis: auto; // Adjust size based on content or set a specific value
        overflow-y: auto; // Allow scrolling
    }

    // Style for the conversation content
    .inboxConversation {
        display: block; // Ensure it's visible
        width: 100%;
        order: 2; // Conversation content comes after the inbox list
        flex-basis: auto; // Adjust size based on content or set a specific value
        max-height: 50vh; // Adjust maximum height as needed
        overflow-y: auto; // Allow scrolling
    }

    // Adjustments for the conversation header and content for mobile
    .inboxConversationHeader {
        padding: 10px;
        font-size: 14px;
    }

    .inboxConversationContent {
        padding: 5px;
    }

    // Additional styles for other elements if needed
}
@media screen and (max-width: 768px) {
    // Assuming the outer div is .inboxConversation
    .inboxConversation {
        overflow-y: auto; // Enable scrolling for the entire conversation container
        max-height: calc(100vh - 60px); // Adjust based on the height of the fixed elements like headers or footers
        position: relative; // Establishes a positioning context for inner elements
    }

    // If there's an inner div that also needs to scroll, handle it separately
    .innerScrollDiv { // Replace with the actual class of the inner div
        overflow-y: auto; // Enable scrolling for the inner content
        max-height: 100%; // Limit its height to the parent container's height
        position: absolute; // Make it relative to the inboxConversation
        top: 0;
        bottom: 60px; // Adjust based on the height of elements like the message text box
        left: 0;
        right: 0;
    }

    // Style adjustments for the message text box
    .messageTextBox {
        position: fixed; // Keeping it fixed at the bottom
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 100; // Ensure it's above other content
    }

    // ... other styles ...
}
@media screen and (max-width: 768px) {
    // Set a fixed height for the conversation area, less than 90% of the viewport height
    .inboxConversation {
        max-height: 80vh; // Adjust this value as needed
        position: relative; // For positioning inner elements
		overflow: hidden; // Prevent scrolling within this container
    }

    // Make only the inner conversation content scrollable
	.inboxConversationContent {
        overflow-y: auto; // Enable vertical scrolling
        // Ensure there's space for the input area at the bottom
        padding-bottom: 120px; // This padding should be equal to or greater than the height of the inboxConversationCta
        // Adjust the bottom value if necessary to ensure it aligns correctly with the bottom of the inboxConversation
        bottom: 120px; // This should match the height of the fixed inboxConversationCta
    }

    // Fixed position for the message input area
    .inboxConversationCta {
        position: fixed;
        bottom: 0; // Align with the bottom of the viewport
        left: 0;
        width: 100%; // Full width
        background: white; // Match the background to your design
        z-index: 10; // Above other content
        height: 120px; // Adjust this based on the actual height of your input area
        box-shadow: 0 -2px 5px rgba(0,0,0,0.1); // Optional shadow for better separation
	}
        // Styling for the textarea and send button
        .inboxConversationInput, .inboxConversationSend {
            // Add specific styles as needed
        }
    }

    // Ensure no content is hidden behind the fixed message input area
    .inboxConversation {
        padding-bottom: 120px; // Adjust based on the height of the inboxConversationCta
    }

	.inboxConversationHeader {
		padding: 25px;
		background-color: #ffffff;
		height: 100px;
		box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);}

		
		@media only screen and (max-width: 767px) {

				.selectedViewingDate {
					background-color: #ffffff;
					width: 100%;
					top: -37px!important;
					padding-top: 10px;
					padding-bottom: 10px;
					z-index: 5;
				}

				body, html {
					height: 100%;
				}
				.inboxPage {
				.inboxContent {
					overflow-y: auto; /* Allow vertical scrolling within the conversation area */
					height: auto; /* Ensure the element takes full viewport height */
				}}

				.css-dev-only-do-not-override-1pg2vfm.ant-float-btn.ant-float-btn-primary.ant-float-btn-circle {
					display: none;
				}

				.inboxPage .inboxContent .inboxConversation .inboxConversationCta{
					bottom: 0;
				}

			}

			.ant-layout-footer.mainFooter {
				display: none;
			}