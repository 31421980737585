@import '../../variables.scss';
.statsViewContainer {
	.statsHeaderTitle {
		margin-top: 0;
	}
	.statsContainer {
		margin-top: 25px;
		margin-bottom: 25px;
	}
	.dashboardFeatureInProgress {
		.ant-result-icon {
			margin-bottom: 0;
		}
		.ant-result-title {
			font-size: 20px;
			font-weight: 700;
			color: $darkGray;
		}
	}
}
