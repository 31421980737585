@import '../../variables.scss';

.breederPage {
	.breederCoverPhoto {
		height: 300px;
		.ant-image {
			width: 100%;
			height: 100%;
			img {
				height: 100%;
				object-position: center;
				object-fit: cover;
			}
		}
	}
	.breederContent {
		max-width: 1600px;
		width: 100%;
		padding: 50px 0;
		margin-left: auto !important;
		margin-right: auto !important;
		.breederCardProfile {
			text-align: center;
			.breederName {
			}
			.breederLocation {
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				gap: 7px;
				color: $textGray;
			}
			.breederDescription {
				margin: 25px 0;
				text-align: center;
			}
			.breederDetails {
				margin-top: 25px;
				.breederDocuments {
					margin-top: 25px;
				}
			}
			.breederContactCta {
				width: 100%;
			}
			.breederCustomersList {
				margin-bottom: 25px;
			}
		}
		.breederCardDocuments {
			margin: 50px 0;
			.breederCardDocumentHeadingTxt {
				text-align: center;
				margin-top: 0;
			}
			.breederCardDocumentList {
				display: flex;
				flex-direction: column;
				gap: 15px;
				margin: 25px 0 0;
			}
		}
		.breederStorefront {
			padding: 0;
		}
	}
	.breederStoreFilterCategory {
		margin-bottom: 25px;
	}
	/**
	* Basic CSS Media Query Template
	* ------------------------------------------
	*  Responsive Grid Media Queries - 1280, 1024, 768, 480
	*   1280-1024   - desktop (default grid)
	*   1024-768    - tablet landscape
	*   768-480     - tablet 
	*   480-less    - phone landscape & smaller
	* --------------------------------------------
	*/
	@media all and (min-width: 1024px) and (max-width: 1280px) {
		.breederContent {
			margin-left: 0!important;
			margin-right: 0!important;
		}
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.breederContent {
			margin-left: 0!important;
			margin-right: 0!important;
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.breederContent {
			margin-left: 0!important;
			margin-right: 0!important;
		}
	}

	@media all and (max-width: 480px) {
		.breederContent {
			margin-left: 0!important;
			margin-right: 0!important;
		}
	}
}
