.accountTypeSelection {
	cursor: pointer;
	width: 50%;
	background-color: #3C3A3B;
	&.selected {
		background: #97c66a;
		.setupSelectionLabel {
			color: #ffffff;
		}
	}
	&:hover {
		background: rgba(151, 198, 106, 0.5);
		.setupSelectionLabel {
			color: #ffffff;
		}
	}
	.accountTypePreviewImg {
		width: 100%;
		height: 175px;
		display: none;
		img {
			width: 100%;
			object-fit: contain;
			height: 100%;
			object-position: center;
		}
	}
	.setupSelectionLabel {
		color: #ffffff;
		text-align: center;
		display: flex;
		justify-content: center;
		font-weight: 500;
	}
}
