@import '../../variables.scss';

.paywallPopup {
	.ant-modal-content {
		border-radius: 0;
	}
	.paywallPopupContent {
		text-align: center;
	}
	.paywallImg {
		margin: 10px 0;
	}
	.paywallTitleTxt {
		margin-top: 0;
	}
	.paywallSubTxt {
		margin-bottom: 15px;
		width: 75%;
		margin-left: auto;
		margin-right: auto;
		color: $textGray;
	}
	.paywallCta {
		border-radius: 0;
		width: fit-content;
		margin: 15px 0;
	}
	.paywallFooterHelp {
		font-size: 13px;
		color: $textGray;
		margin-top: 5px;
	}
}