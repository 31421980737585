.pendingApprovalPage {
    margin: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 90vh;
    .customPendingApprovalResult {
        width: 100%;
        max-width: 600px;
        min-width: auto;
        margin: auto;
    }
}