@import '../../variables.scss';

.breederCategoryCard {
	cursor: pointer;
	transition: 0.4s, background-position 0s;
	&:hover {
		background-color: #97c66a;
		.breederCategoryName {
			color: #ffffff;
		}
		i {
			color: #ffffff;
		}
	}
	&.selected {
		background-color: #97c66a;
		.breederCategoryName {
			color: #ffffff;
		}
		i {
			color: #ffffff;
		}
	}
	.breederCategoryName {
		margin: 0;
		text-align: center;
		text-transform: capitalize;
	}
	.categoryIconContainer {
		background-color: $primaryColorLight;
		width: 50px;
		border-radius: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
		i {
			margin: auto;
			text-align: center;
			display: flex;
			justify-content: center;
		}
	}
	/**
	* Basic CSS Media Query Template
	* ------------------------------------------
	*  Responsive Grid Media Queries - 1280, 1024, 768, 480
	*   1280-1024   - desktop (default grid)
	*   1024-768    - tablet landscape
	*   768-480     - tablet 
	*   480-less    - phone landscape & smaller
	* --------------------------------------------
	*/
	

	@media all and (max-width: 480px) {
		.breederCategoryName {
			padding-top: 510x!important;
			font-size: 0.89rem;
			padding-top: 10px;
		}
		.ant-card-body {
			padding: 15px;
			margin: 0px;
		}
		.categoryIconContainer {
			padding: 10px;
			height: auto;
			width: auto;
			margin-bottom: 0;
		}
	}
}
