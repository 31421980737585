.missingPage {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #FAFAFA;
    .missingContent {
        width: 100%;
        max-width: 600px;
        min-width: auto;
        text-align: center;
        margin: auto;
        .missingHeaderTxt {

        }
        .missingSubTxt {
            color: #000000;
        }
        .missingImg {
            width: 400px;
        }
    }
}