.dashboardPage {
	height: auto;
	min-height: 100%;
	max-height: 100%;
	.dashboardSider {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
		height: 100vh;
		position: fixed;
		left: 0px;
		top: 0px;
		bottom: 0px;
		.dashboardMenuLogo {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
			margin-top: 25px;
			margin-bottom: 25px;
		}
		.dashboardMenu {
			border: unset;
		}
	}
	.dashboardContentView {
		margin-left: 200px;
		height: 100%;
		.dashboardHeader {
			height: 80px;
			background-color: #ffffff;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
			display: flex;
			align-items: center;
			padding: 25px;
			.dashboardHeaderTxt {
				margin: 0;
			}
		}
		.dashboardContent {
			margin: 25px;
		}
	}
}
.dashboardPage {
    height: auto;
    min-height: 100%;
    max-height: 100%;

    /* Styles for .dashboardSider */
    .dashboardSider {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
        height: 100vh;
        position: fixed;
        left: 0px;
        top: 0px;
        bottom: 0px;

        /* Styles for .dashboardMenuLogo */
        .dashboardMenuLogo {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 25px;
            margin-bottom: 25px;
        }

        /* Styles for .dashboardMenu */
        .dashboardMenu {
            border: unset;
        }
    }

    /* Styles for .dashboardContentView */
    .dashboardContentView {
        margin-left: 200px;
        height: 100%;

        /* Styles for .dashboardHeader */
        .dashboardHeader {
            height: 80px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
            display: flex;
            align-items: center;
            padding: 25px;

            /* Styles for .dashboardHeaderTxt */
            .dashboardHeaderTxt {
                margin: 0;
            }
        }

        /* Styles for .dashboardContent */
        .dashboardContent {
            margin: 25px;
        }
    }

    /* pups for sale mobile view */
    @media (max-width: 768px) {
        .dashboardSider {
            /* Adjust sidebar for mobile, possibly hide or make it a collapsible menu */
            height: auto;
			z-index: 300;
        }

        .dashboardContentView {
            margin-left: 0; /* Remove margin for mobile view */
            
            .dashboardHeader {
                height: auto; /* Adjust header height for mobile */
                padding: 15px; /* Reduce padding for mobile */
            }

            .dashboardContent {
                margin: 15px; /* Reduce margin for mobile */
            }


/* Ensure that the body and html do not overflow horizontally */
body, html {
    overflow-x: hidden; /* Hide horizontal overflow */
}


/* Pups for sale mobile css */
@media only screen and (max-width: 768px) {
	/* Convert table layout to block for mobile */
	table, .ant-table-thead, .ant-table-body, .ant-table-row, .ant-table-cell {
	  display: block;
	}
  
	/* Hide table headers on mobile */
	.ant-table-thead {
	  display: none;
	}
  
	/* Style cells to look more like cards */
	.ant-table-cell {
	  display: flex;
	  align-items: center;
	  margin-bottom: 15px; /* Add space between entries */
	  border-bottom: none; /* Remove bottom border */
	}
  
	/* Adjust image sizing and remove absolute positioning */
	.ant-image-img {
	  width: 70%; /* Adjust the width as needed */
	  margin-right: 10px; /* Add space between image and text */
	  height: auto;
	  border-radius: 5px;
	  flex-shrink: 0; /* Prevent the image from shrinking */
	}
  
	/* Make bio text fully visible */
	.ant-table-cell-ellipsis {
	  white-space: normal;
	  overflow: visible;
	  text-overflow: clip;
	}
  
	/* Adjust font sizes and weights for mobile */
	.mobile-name, .mobile-price {
	  font-weight: bold;
	}
  
	.mobile-bio {
	  font-size: 0.85em;
	  line-height: 1.4;
	}
  }
  
  /* upcoming listings Mobile screen adjustments */
@media only screen and (max-width: 768px) {
	/* Adjust the layout for mobile viewing */
	.ant-table-cell.tableTitleContent {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  padding: 10px; /* Add some padding */
	}
  
	/* Adjust the image sizing for mobile */
	.ant-table-cell .tableTitleContent .ant-image,
	.ant-table-cell .tableTitleContent .breederCardImg {
	  width: 80%; /* Make images larger and more centered */
	  margin-bottom: 5px; /* Add space below each image */
	}
  
	/* Remove the side-by-side image layout for mobile */
	.ant-table-cell .tableTitleContent div:first-child {
	  flex-direction: column;
	}
  
	/* Remove additional margin between images for mobile */
	.ant-table-cell .tableTitleContent .ant-image + .ant-image {
	  margin-left: 0;
	}
  
	/* Center the content inside the first child div */
	.ant-table-cell .tableTitleContent div:first-child div {
	  max-width: 100%;
	  left: 0;
	  position: relative;
	  text-align: center;
	}
  
	/* Adjust the pseudo-elements for mobile */
	.ant-table-cell .tableTitleContent div:first-child div::before {
	  width: auto; /* Let the width be content-based */
	  font-size: smaller; /* Reduce font size for smaller screens */
	  top: -1rem; /* Position label above the image */
	  left: 50%;
	  transform: translateX(-50%);
	  text-align: center;
	}
  
	/* Ensure ellipsis text displays fully on mobile */
	.ant-table-cell-ellipsis {
	  overflow: visible;
	  white-space: normal;
	  text-overflow: clip;
	}
  
	.datatableViewContainer .datatableTableContainer .tableTitleContent{
		display: grid;
		align-items: center;
		gap: -15px;
	}
		
	/* menu icon */
	@media only screen and (max-width: 768px) {

	.ant-layout-sider-zero-width-trigger .ant-layout-sider-zero-width-trigger-left {
		position: static !important; /* Example property to override */
		top: 15px !important;
		inset-inline-end: 10px !important;
		z-index: 1;
		width: 40px;
		height: 40px;
		color: #fff;
		font-size: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #001529;
		border-start-start-radius: 0;
		border-start-end-radius: 6px;
		border-end-end-radius: 6px;
		border-end-start-radius: 0;
		cursor: pointer;
		transition: background 0.3s ease;
  }
}
  
        }
    }
}
}