.homepage {
	.homepageBannerHero {
		position: relative;
		.homepageBannerImgContainer {
			height: 300px;
			background: black;
			.homepageBannerImg {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				opacity: 0.5;
			}
		}
		.homepageBannerTitle {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #ffffff;
			font-weight: 900;
			text-align: center;
		}
	}
}


.breederStoreFilterCategory.custom-width {
    width: 90%; // or any specific width you want
    margin: 0 auto; // centre the container


    // Ensuring it overrides any padding set by ant-row
    &.ant-row-center {
        padding: 0 !important; // Override padding if needed
    }
}