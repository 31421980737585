@import '../../../variables.scss';
.publicNavbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
	.navLink {
		display: flex;
		gap: 10px;
		align-items: center;
		color: #ffffff;
		b {
			font-weight: normal;
		}
		&.selected {
			z-index: 1;
			border-bottom: 2px solid $primaryColor;
			color: #ffffff;
			b {
				font-weight: 700;
				color: #ffffff;
			}
			i {
				color: #ffffff;
			}
		}
	}
	.publicNavLogo {
		a {
			display: flex;
			align-items: center;
			font-size: 25px;
			font-family: impact;
			letter-spacing: 2px;
			color: #a1c575;
			.publicNavLogoImg {
				width: 45px;
				height: 45px;
			}
		}
	}
	.publicNavCenter {
		display: flex;
		gap: 35px;
		align-items: center;
		justify-content: center;
		margin-right: auto;
		margin-left: auto;
	}
	.publicNavProfile {
		display: flex;
		align-items: center;
		gap: 35px;
		.loginCta {
			color: #ffffff;
		}
		.signUpCta {
			width: 100px;
			display: flex;
			justify-content: center;
			text-align: center;
			color: #ffffff;
			background: $primaryColor;
		}
	}
	.publicMobileNavItem {
		display: none;
		background: $primaryColorLight;
		padding: 0 15px;
		cursor: pointer;
		i {
			color: #ffffff;
		}
	}
	/**
	* Basic CSS Media Query Template
	* ------------------------------------------
	*  Responsive Grid Media Queries - 1280, 1024, 768, 480
	*   1280-1024   - desktop (default grid)
	*   1024-768    - tablet landscape
	*   768-480     - tablet 
	*   480-less    - phone landscape & smaller
	* --------------------------------------------
	*/
	@media all and (min-width: 1024px) and (max-width: 1280px) {
		.navLink {
			b {
				display: none;
			}
		}
	}

	@media all and (min-width: 768px) and (max-width: 1024px) {
		.navLink {
			b {
				display: none;
			}
		}
	}

	@media all and (min-width: 480px) and (max-width: 768px) {
		.navLink {
			b {
				display: none;
			}
		}
	}

	@media all and (max-width: 480px) {
		.publicMobileNavItem {
			display: flex;
		}
		.publicNavLogo {
			padding-left: 25px;
		}
		.publicNavCenter {
			display: none;
		}
		.publicNavProfile {
			display: none;
		}
	}
}